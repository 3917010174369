import React, { useState } from "react"
import Layout from "../components/layout"
import Seo from "../components/SEO/Seo"
import "../styles/empleo.scss"
import TextareaAutosize from "react-textarea-autosize"

import { IconContext } from "react-icons"
import { GrFormTrash } from "react-icons/gr"
import { Link } from "gatsby"
import { useGoogleReCaptcha } from "react-google-recaptcha-v3"

const Empleo = props => {
  const [nombre, setNombre] = useState("")
  const [email, setEmail] = useState("")
  const [telefono, setTelefono] = useState("")
  const [mensaje, setMensaje] = useState("")
  const [CV, setCV] = useState()
  const [isChecked, setIsChecked] = useState(false)
  const [mensajeError, setMensajeError] = useState("")
  const [respuesta, setRespuesta] = useState("")
  const [valido, setValido] = useState(false)

  const { executeRecaptcha } = useGoogleReCaptcha()
  // const [token, setToken] = useState('')

  const validarArchivo = e => {
    const file = e.target.files[0]
    // file = e.target.files[0];
    const divMsg = document.getElementById("mensaje-archivo")

    if (!file) {
      // if (file.length === 0) {
      divMsg.textContent = "Por favor adjunta un archivo"
      divMsg.style.background = "darkred"
      divMsg.style.color = "white"
      setValido(false)
    } else {
      if (/\.(doc|docx|pdf|odt)$/i.test(file.name) === false) {
        // alert("Formato no valido!");
        divMsg.textContent = "Formato de archivo no válido"
        divMsg.style.background = "darkred"
        divMsg.style.color = "white"
        setValido(false)
      } else {
        divMsg.textContent = file.name
        divMsg.style.background = "lightgreen"
        divMsg.style.color = "#1f1f1f"
        setValido(true)
        // setCV(file)
      }
    }
  }

  const handleSubmit = async e => {
    e.preventDefault()
    // Check if the captcha was skipped or not
    if (!executeRecaptcha) {
      return
    }

    // This is the same as grecaptcha.execute on traditional html script tags
    const result = await executeRecaptcha("contacto")
    //--> grab the generated token by the reCAPTCHA
    // Prepare the data for the server, specifically body-parser

    // const data = JSON.stringify({ nombre, email, telefono, mensaje, result })

    const formData = new FormData()
    const data = {}
    let archivo = ""
    data["Nombre"] = nombre
    data["Email"] = email
    data["Mensaje"] = mensaje
    data["Telefono"] = telefono
    data["result"] = result

    if (valido) {
      archivo = document.getElementById("curriculum").files[0]
    }

    formData.append("files.curriculum", archivo)
    formData.append("data", JSON.stringify(data))

    // POST request to your server
    fetch(`${process.env.API_URL}/cvagencias`, {
      method: "POST",
      body: formData, //--> this contains your data
    })
      .then(res => res.json())
      .then(data => {
        const success = data.success
        const score = data.score

        if (success && score >= 0.7) {
          setMensajeError("Gracias por ponerte en contacto con nosotros")
          setRespuesta("correcto")
          setNombre("")
          setCV("")
          setEmail("")
          setTelefono("")
          setMensaje("")
          setIsChecked(false)
        } else {
          setMensajeError(
            "Ha habido un error en el envío del formulario, por favor inténtalo de nuevo"
          )
          setRespuesta("error")
        }
      })
  }

  return (
    <div>
      <Layout>
        <Seo title="Empleo" pathname={props.location.pathname} />
        <div className="primera-seccion cuerpo-empleo container">
          <div className="texto-empleo">
            <h1>
              ¿Quieres formar parte de nuestro{" "}
              <span className="subrayado">equipo</span>?
            </h1>
            <p className="destacado">
              Tienes ideas a exportar. Te lo flipa el marketing digital, el
              diseño o el desarrollo web o de aplicaciones.. ¿y no te conocemos?
              “Nosotros no entender”.
              <br />
              Escríbenos, mandanos un link con tu porfolio, envíanos tu
              curriculum o lo que creas que más te representa porque queremos
              saber de ti.
            </p>
          </div>
          <div className="caja-formulario">
            <form
              className="form-empleo"
              method="post"
              encType="multipart/form-data"
              onSubmit={handleSubmit}
            >
              <input
                type="text"
                name="nombre"
                placeholder="Nombre"
                required
                value={nombre}
                onChange={e => setNombre(e.target.value)}
                style={{ fontFamily: "Now-black-light" }}
              />

              <input
                type="email"
                name="email"
                placeholder="Email"
                required
                value={email}
                onChange={e => setEmail(e.target.value)}
                style={{ fontFamily: "Now-black-light" }}
              />

              <input
                type="tel"
                name="telefono"
                placeholder="Nº teléfono"
                maxLength="14"
                minLength="9"
                required
                value={telefono}
                onChange={e => setTelefono(e.target.value)}
                style={{ fontFamily: "Now-black-light" }}
              />

              {/* <input
                                    name="CV"
                                    type="file"
                                    required
                                    value={CV}
                                    onChange={e => setCV(e.target.value)}
                                    style={{ display: "none" }}
                                />
                                <button className="boton-archivo" onClick={() => this.refs.fileInput.click()}>Upload File</button> */}
              <div>
                <label htmlFor="curriculum" className="boton-archivo">
                  Subir CV (doc/docx/odt/pdf)
                  <input
                    type="file"
                    name="curriculum"
                    id="curriculum"
                    // onChange={e => setCV(e.target.files[0])}
                    onChange={e => validarArchivo(e)}
                    multiple={false}
                    required
                  />
                </label>
                {CV ? (
                  <span id="mensaje-archivo">
                    CV.name <GrFormTrash onClick={setCV("")} />
                  </span>
                ) : (
                  <span id="mensaje-archivo">Por favor sube tu CV</span>
                )}
              </div>

              {/* <br /> */}
              <div>
                <TextareaAutosize
                  name="mensaje"
                  id="mensaje"
                  placeholder="Escribe aquí tu mensaje..."
                  value={mensaje}
                  onChange={e => setMensaje(e.target.value)}
                />
              </div>
              <br />
              <div className="caja-checkbox">
                <input
                  type="checkbox"
                  id="privacidad"
                  name="privacidad"
                  // defaultChecked={isChecked}
                  checked={isChecked}
                  onChange={() => setIsChecked(!isChecked)}
                />
                <label htmlFor="privacidad" className="check-box"></label>
                <label htmlFor="privacidad">
                  He leído y acepto la{" "}
                  <Link to="/politica-de-privacidad/">
                    política de privacidad
                  </Link>
                </label>
              </div>

              <div
                className={`mensaje-formulario
                                ${respuesta}                              
                            `}
                dangerouslySetInnerHTML={{ __html: mensajeError }}
              />

              <IconContext.Provider value={{ color: "#1f1f1f" }}>
                <div className="icono-input">
                  <input
                    type="submit"
                    value="Enviar"
                    className="boton-enviar"
                    disabled={isChecked && valido ? false : true}
                  />
                </div>
              </IconContext.Provider>
            </form>
            <p>
              <small className="texto-recaptcha">
                Este sitio está protegido por reCAPTCHA y se aplican la{" "}
                <a href="https://policies.google.com/privacy">
                  política de privacidad
                </a>{" "}
                y los{" "}
                <a href="https://policies.google.com/terms">
                  términos del servicio
                </a>{" "}
                de Google.
              </small>
            </p>
          </div>
        </div>
      </Layout>
    </div>
  )
}

export default Empleo
